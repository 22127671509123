<template>
    <div id="lottery_record">
        <h2 style="margin: 0;padding: 10px 16px 5px;color: rgba(69, 90, 100, 0.6);font-weight: normal;font-size: 14px;line-height: 16px;">
            <span>达人昵称</span>
            <span style="float: right;">参与时间</span>
        </h2>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="loadData">
            <van-cell-group>
                <van-cell v-for="(item,index) in list" :key="index" :title="item.account_name" :value="item.created_at">
                    <template #icon>
                        <van-icon :name="item.headimgurl" size="24" style="margin-right: 3px;"></van-icon>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
    import {Dialog} from "vant";
    export default {
        name: "LotteryRecord",
        data(){
            return{
                act_id:0,
                pageNo:1,
                pageSize:100,
                list:[],
                total:0,
                loading:false,
                finished:false,
            }
        },
        mounted() {
            this.act_id = this.$route.query.act_id;
        },
        methods:{
            loadData(){
                let that = this;
                this.loading = true;
                this.post('/api/client/activity/participate/record',{
                    act_id:this.act_id,
                    pageNo:this.pageNo,
                    pageSize:this.pageSize,
                }).then(result => {
                    if(result.code === 0){
                        for(let i=0;i<result.data.length;i++){
                            this.list.push(result.data[i]);
                        }
                        that.pageNo ++;
                        if(result.data.length < that.pageSize || !result.data.length){
                            that.finished = true;
                        }
                        that.loading = false;
                    } else {
                        Dialog({ message: result.msg});
                        that.$router.replace('/lottery');
                    }
                })
            },
        },

    }
</script>

<style scoped>

</style>
<style>
    #lottery_record .van-cell__title{
        line-height: 24px;
    }
</style>